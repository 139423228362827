import Api from "../pages/loginSignin/Api";
import { refreshToken } from "../utils/auth"; // Create this utility

export const submitCartToBackend = async () => {
  try {
    const customerId = sessionStorage.getItem("customer_id");
    const rawCartData = sessionStorage.getItem("cart");
    const token = localStorage.getItem("access_token");

    if (!token) throw new Error("No authentication token found");
    if (!rawCartData) throw new Error("Cart data not found in session storage");

    const parsedData = JSON.parse(rawCartData);
    const cartItems = parsedData.cart_items;

    if (!customerId || !Array.isArray(cartItems) || cartItems.length === 0) {
      throw new Error("Invalid cart data or missing customer ID");
    }

    const cartPayload = {
      customer_id: parseInt(customerId),
      site_visit: parsedData.site_visit || false,
      cart_items: cartItems.map((item) => ({
        productid: item.id,
        name: item.name,
        quantity: parseInt(item.quantity) || 1,
        price: parseFloat(item.total) / parseInt(item.quantity), // Ensure correct unit price
        total: parseFloat(item.total),
        size: {
          width: parseFloat(item.customSize?.width) || 0,
          height: parseFloat(item.customSize?.height) || 0,
        },
        design_image: item.design_image || "https://st4.depositphotos.com/17828278/24401/v/450/depositphotos_244011872-stock-illustration-image-vector-symbol-missing-available.jpg",
        unit: item.customSize?.unit || "inches", // Ensure correct unit
        hire_designer_id: Number(item.designer_package?.id) || null,
        design_description: item.design_note || "No description provided",
        is_smart: item.isSmart || false,
        deliveryId: item.deliveryId || null,
        thicknessId: item.thicknessId || null,
        turnAroundId: item.turnAroundId || null,
        Installation_type_id: item.installation_type_id || null,
        distance: item.distance || 0,
      })),
    };
        
    console.log("Final cart payload:", JSON.stringify(cartPayload, null, 2));

    const response = await Api.post("cart/", cartPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });

    if (response.data?.cart?.cart_id) {
      sessionStorage.setItem("cart_id", response.data.cart.cart_id.toString());
    }

    return response.data;
  } catch (error) {
    console.error("Cart submission error:", error);
    throw new Error(
      error.response?.data?.detail || error.message || "Failed to submit cart"
    );
  }
};