"use client";

import { useState, useEffect } from "react";
import { X} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import useNavigateToEditor from "../hooks/useNavigateToEditor";
import Amazone from "../assets/images/amazone.png";
import Cart from "../assets/images/cartmodal.png";
import HireDesigner from "../assets/images/dismodal.png";
import DesignTool from "../assets/images/editormodal.png";
import Upload from "../assets/images/upload.png";

const DesignModal = ({ isOpen, onClose, productDetails }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const { navigateToEditor } = useNavigateToEditor();

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      // Prevent background scrolling when modal is open
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to restore scrolling when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, 300); // Match with animation duration
  };

  // If modal is not open, don't render anything
  if (!isOpen) return null;

  const handleUploadDesign = () => {
    handleClose();
    setTimeout(() => {
      navigate("/design-upload", {
        state: {
          productDetails,
        },
      });
    }, 300);
  };

  const handleHireDesigner = () => {
    handleClose();
    setTimeout(() => {
      navigate("/hire-designer", {
        state: {
          productDetails,
        },
      });
    }, 300);
  };

  const handleAddToCartDesign = () => {
    handleClose();

    // Add timestamp to the productDetails
    const updatedProductDetails = {
      ...productDetails,
      timestamp: Date.now(),
    };
    // Retrieve cart object from sessionStorage or initialize a new one
    const cart = JSON.parse(sessionStorage.getItem("cart")) || {
      cart_items: [],
    };

    // Add the new item to cart_items array
    cart.cart_items.push(updatedProductDetails);

    // Save updated cart back to sessionStorage
    sessionStorage.setItem("cart", JSON.stringify(cart));

    // Also store cart_items separately in sessionStorage for direct access
    sessionStorage.setItem("cart_items", JSON.stringify(cart.cart_items));

    navigate("/cart");
  };

  const options = [
    {
      title: "Hire a designer",
      description: "Save time and Bring your vision to life with a designer",
      imageSrc: HireDesigner, // Placeholder for your image
      imageAlt: "Designer icon",
      onClick: handleHireDesigner,
      disabled: false,
    },
    {
      title: "Design your own",
      description: "Create your custom artwork from scratch.",
      imageSrc: DesignTool, // Placeholder for your image
      imageAlt: "Design tool icon",
      onClick: navigateToEditor,
      disabled: false,
    },
    {
      title: "Upload your design",
      description:
        "Have a ready design? Upload it and get it printed on your material of choice.",
      imageSrc: Upload, // Placeholder for your image
      imageAlt: "Upload icon",
      onClick: handleUploadDesign,
      disabled: false,
    },
    {
      title: "",
      description: "",
      disabled: true,
      buttons: productDetails.amazon_url
        ? [
            {
              label: "Add to cart",
              description: "(Without customization)",
              imageSrc: Cart, // Placeholder for your image
              imageAlt: "Shopping cart icon",
              arrow: "→",
              className:
                "flex flex-col items-center justify-center w-full bg-white rounded-lg p-2 cursor-pointer ",
              onClick: handleAddToCartDesign,
            },
            {
              label: "Buy on amazon",
              description: "(Without customization)",
              imageSrc: Amazone, // Placeholder for your image
              imageAlt: "Amazon icon",
              arrow: "→",
              className:
                "flex flex-col items-center justify-center w-full p-2 bg-white cursor-pointer rounded-lg  ",
              onClick: () => window.open(productDetails.amazon_url, "_blank"),
            },
          ]
        : [
            {
              label: "Add to cart",
              description: "(Without customization)",
              imageSrc: Cart, // Placeholder for your image
              imageAlt: "Shopping cart icon",
              arrow: "→",
              className:
                "flex flex-col items-center justify-center w-full p-2 bg-white rounded-lg hover:border-red-500 hover:shadow-lg transition-all",
              onClick: handleAddToCartDesign,
            },
          ],
    },
  ];

  // Filter options based on whether direct add to cart is allowed
  const displayOptions = options.filter((option, index) =>
    productDetails.allow_direct_add_to_cart ? true : index < 3
  );

  // Determine appropriate grid layout based on number of options
  const gridClass =
    displayOptions.length === 3
      ? "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8"
      : "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-8";

  return (
    <motion.div
      className="fixed inset-0 bg-black flex items-center justify-center p-2 sm:p-4 z-50 overflow-y-auto"
      initial={{ opacity: 0 }}
      animate={{
        opacity: isVisible ? 1 : 0,
        backgroundColor: isVisible ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)",
      }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        className="bg-white rounded-lg shadow-xl w-full max-w-6xl mx-auto p-4 sm:p-6 md:p-8 relative"
        initial={{ scale: 0.9, y: 20 }}
        animate={{ scale: isVisible ? 1 : 0.9, y: isVisible ? 0 : 20 }}
        transition={{ duration: 0.3, type: "spring", stiffness: 300 }}
      >
        <motion.button
          onClick={handleClose}
          className="absolute top-2 right-2 sm:top-4 sm:right-4 text-gray-400 hover:text-red-500 transition-colors"
          whileHover={{ scale: 1.1, rotate: 90 }}
          transition={{ duration: 0.2 }}
        >
          <X size={24} />
        </motion.button>

        <motion.h2
          className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 sm:mb-8 text-center"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1, duration: 0.3 }}
        >
          Create and Customize Your Design
        </motion.h2>

        <div className={gridClass}>
          {displayOptions.map((option, index) => (
            <motion.div
              key={index}
              onClick={option.disabled ? undefined : option.onClick}
              className={`test group ${
                option.buttons ? "p-0" : "px-3 py-4 sm:px-4 sm:py-6"
              } border rounded-lg transition-all duration-200 flex flex-col items-center h-auto sm:h-56 md:h-64 shadow-sm
              ${
                option.disabled
                  ? "opacity-100 border-gray-200"
                  : "hover:shadow-lg cursor-pointer group border-gray-200 hover:border-red-500"
              }`}
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 * (index + 1), duration: 0.3 }}
              whileHover={
                !option.disabled
                  ? { boxShadow: "0 10px 25px -5px rgba(0, 0, 0, 0.1)" }
                  : {}
              }
            >
              {!option.buttons && (
                <>
                  {option.imageSrc && (
                    <div className="flex test-img transform transition-transform duration-300 group-hover:scale-120 justify-center mb-2 sm:mb-4 h-12 sm:h-[80px] overflow-hidden">
                      <motion.img
                        src={option.imageSrc}
                        alt={option.imageAlt}
                        className="h-full object-contain"
                      />
                    </div>
                  )}

                  <div className="flex flex-col items-center justify-center flex-1">
                    {option?.title && (
                      <p
                      // style={{fontWeight:"600"}}
                        className={`text-lg
                           font-semibold  text-gray-800 mb-2 sm:mb-3 text-center
                      ${
                        option.disabled && "group-hover:text-red-500"
                      } transition-colors`}
                      >
                        {option.title}
                      </p>
                    )}

                    {option?.description && (
                      <p className="text-gray-600 text-center text-xs sm:text-sm">
                        {option.description}
                      </p>
                    )}
                  </div>
                </>
              )}

              {option.buttons && (
                <div className="flex flex-col w-full h-full justify-between items-center">
                  {option.buttons.map((btn, idx) => (
                    <div
                      key={idx}
                      className="w-full h-full flex justify-center group/item border-b border-gray-200"
                    >
                      <motion.button
                        className={btn.className}
                        onClick={btn.onClick}
                        whileHover={{ border: "1px solid red" }}
                        transition={{ duration: 0.2 }}
                      >
                        {btn.imageSrc && (
                          <motion.img
                            src={btn.imageSrc}
                            alt={btn.imageAlt}
                            className="h-8 w-8 sm:h-10 sm:w-10 object-contain  transform transition-transform duration-200 group-hover/item:scale-115"
                            transition={{ duration: 0.2 }}
                          />
                        )}
                        <span className="font-medium text-gray-800 flex items-center text-sm sm:text-base">
                          {btn.label}
                          <span className="ml-1 sm:ml-2 text-red-500">
                            {btn.arrow}
                          </span>
                        </span>
                        <span className="text-xs text-gray-500 mt-1">
                          {btn.description}
                        </span>
                      </motion.button>
                    </div>
                  ))}
                </div>
              )}
            </motion.div>           
          ))}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default DesignModal;