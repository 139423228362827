"use client"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Mail, FileDown, Loader, Save, History, Printer } from "lucide-react"
import { toast } from "react-toastify"

export default function RFQs({ cart, includeSiteVisit, siteVisitFee, generatePDF, isGeneratingPdf }) {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [mobile, setMobile] = useState("")
  const [company, setCompany] = useState("")
  const [saveName, setSaveName] = useState("")
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [isSendingRFQ, setIsSendingRFQ] = useState(false)
  const [isSavingRFQ, setIsSavingRFQ] = useState(false)
  const [isSubmittingDownload, setIsSubmittingDownload] = useState(false)
  const [isSubmittingPrint, setIsSubmittingPrint] = useState(false)

  // Form data for download/print
  const [downloadData, setDownloadData] = useState({
    name: "",
    email: "",
    mobile: "",
    company: "",
  })

  const [printData, setPrintData] = useState({
    name: "",
    email: "",
    mobile: "",
    company: "",
  })

  // Handle input change for download form
  const handleDownloadInputChange = (e) => {
    const { name, value } = e.target
    setDownloadData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  // Handle input change for print form
  const handlePrintInputChange = (e) => {
    const { name, value } = e.target
    setPrintData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  // Send RFQ function
  const sendRFQRequest = async () => {
    if (!email || !name || !mobile || !company) return

    setIsSendingRFQ(true)

    const payload = {
      email,
      name,
      mobile,
      company,
      cart_items: cart.cart_items.map((item) => ({
        name: item.name,
        size: item.standardSize || `${item.customSize?.width}x${item.customSize?.height} ${item.measurementUnit}`,
        quantity: item.quantity,
        unit_price: (
          (Number.parseFloat(item.total) - Number.parseFloat(item.designerFee || 0)) /
          item.quantity
        ).toFixed(2),
        total: item.total,
      })),
      subtotal: cart.cart_items.reduce((sum, item) => sum + Number.parseFloat(item.total), 0).toFixed(2),
      site_visit: includeSiteVisit,
      site_visit_fee: includeSiteVisit ? siteVisitFee : 0,
      total: (
        cart.cart_items.reduce((sum, item) => sum + Number.parseFloat(item.total), 0) +
        (includeSiteVisit ? siteVisitFee : 0)
      ).toFixed(2),
    }

    console.log("Sending payload:", payload)
    try {
      const response = await fetch("https://dash.brandexperts.ae/send-rfq/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      })

      if (!response.ok) throw new Error("Failed to send RFQ")

      toast.success("RFQ sent successfully!")
      setShowEmailModal(false)
      setEmail("")
      setName("")
      setMobile("")
      setCompany("")
    } catch (error) {
      toast.error("Failed to send RFQ. Try again.")
    } finally {
      setIsSendingRFQ(false)
    }
  }

  // Save RFQ function
  const saveRFQ = async () => {
    if (!saveName.trim()) {
      toast.error("Please enter a name for this RFQ")
      return
    }

    setIsSavingRFQ(true)

    const rfqData = {
      name: saveName,
      cart_items: cart.cart_items.map((item) => ({
        name: item.name,
        standardSize: item.standardSize,
        customSize: item.customSize,
        measurementUnit: item.measurementUnit,
        quantity: item.quantity,
        total: item.total,
        designerFee: item.designerFee,
        design_image: item.design_image,
        timestamp: item.timestamp,
      })),
      site_visit: includeSiteVisit,
      site_visit_fee: includeSiteVisit ? siteVisitFee : 0,
      total: (
        cart.cart_items.reduce((sum, item) => sum + Number.parseFloat(item.total), 0) +
        (includeSiteVisit ? siteVisitFee : 0)
      ).toFixed(2),
      saved_date: new Date().toISOString(),
    }

    try {
      const savedRFQs = JSON.parse(localStorage.getItem("savedRFQs") || "[]")
      savedRFQs.push(rfqData)
      localStorage.setItem("savedRFQs", JSON.stringify(savedRFQs))

      toast.success(`RFQ "${saveName}" saved successfully`)
      setShowSaveModal(false)
      setSaveName("")
    } catch (error) {
      toast.error("Failed to save RFQ. Try again.")
    } finally {
      setIsSavingRFQ(false)
    }
  }

  // Handle download RFQ with client data submission
  const handleDownloadSubmit = async () => {
    // Validate form
    if (!downloadData.name || !downloadData.email || !downloadData.mobile || !downloadData.company) {
      toast.error("Please fill in all fields")
      return
    }

    setIsSubmittingDownload(true)

    try {
      // Send data to API
      const response = await fetch("https://dash.brandexperts.ae/create-download-client/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: downloadData.name,
          email: downloadData.email,
          mobile: downloadData.mobile,
          company: downloadData.company,
        }),
      })

      if (!response.ok) throw new Error("Failed to submit client data")

      const data = await response.json()
      console.log("Download client data response:", data)

      if (data.status === "success") {
        // Close modal and proceed with download
        setShowDownloadModal(false)

        // Reset form
        setDownloadData({
          name: "",
          email: "",
          mobile: "",
          company: "",
        })

        // Call the original generatePDF function
        generatePDF()

        toast.success("Client information submitted successfully")
      } else {
        throw new Error(data.message || "Failed to submit client data")
      }
    } catch (error) {
      console.error("Error submitting client data:", error)
      toast.error("Failed to submit client information. Please try again.")
    } finally {
      setIsSubmittingDownload(false)
    }
  }

  // Handle print RFQ with client data submission
  const handlePrintSubmit = async () => {
    // Validate form
    if (!printData.name || !printData.email || !printData.mobile || !printData.company) {
      toast.error("Please fill in all fields")
      return
    }

    setIsSubmittingPrint(true)

    try {
      // Send data to API
      const response = await fetch("https://dash.brandexperts.ae/create-print-client/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: printData.name,
          email: printData.email,
          mobile: printData.mobile,
          company: printData.company,
        }),
      })

      if (!response.ok) throw new Error("Failed to submit client data")

      const data = await response.json()
      console.log("Print client data response:", data)

      if (data.status === "success") {
        // Close modal and proceed with print
        setShowPrintModal(false)

        // Reset form
        setPrintData({
          name: "",
          email: "",
          mobile: "",
          company: "",
        })

        // Call the original print function
        handlePrintRFQ()

        toast.success("Client information submitted successfully")
      } else {
        throw new Error(data.message || "Failed to submit client data")
      }
    } catch (error) {
      console.error("Error submitting client data:", error)
      toast.error("Failed to submit client information. Please try again.")
    } finally {
      setIsSubmittingPrint(false)
    }
  }

  // Print RFQ function
  const handlePrintRFQ = () => {
    if (cart.cart_items.length === 0) {
      toast.error("Your cart is empty!")
      return
    }

    // Calculate totals
    const subtotal = cart.cart_items.reduce((sum, item) => sum + Number.parseFloat(item.total), 0).toFixed(2)
    const vat = (Number.parseFloat(subtotal) * 0.05).toFixed(2)
    const total = (
      Number.parseFloat(subtotal) +
      Number.parseFloat(vat) +
      (includeSiteVisit ? siteVisitFee : 0)
    ).toFixed(2)

    // Calculate date 30 days from now for Valid Until
    const validUntil = new Date()
    validUntil.setDate(validUntil.getDate() + 30)
    const formattedValidUntil = validUntil.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })

    // Create a hidden iframe for printing
    const printIframe = document.createElement("iframe")
    printIframe.style.position = "absolute"
    printIframe.style.top = "-9999px"
    printIframe.style.left = "-9999px"
    printIframe.style.width = "0"
    printIframe.style.height = "0"
    document.body.appendChild(printIframe)

    // Create printable HTML with matching PDF styling
    const printContent = `
    <!DOCTYPE html>
<!DOCTYPE html>
<html>
  <head>
    <title>Brand Experts Quote</title>
    <style>
      @media print {
        @page { size: A4; margin: 0; }
        body { 
          font-family: Helvetica, Arial, sans-serif;
          margin: 0;
          padding: 20mm;
          color: #000;
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
        * { box-sizing: border-box; }
        
        .page {
          position: relative;
          width: 210mm;
          min-height: 297mm;
          padding: 20mm;
          margin: 0 auto;
        }
        
        .header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15mm;
        }
        
        .logo {
          width: 50mm;
          height: auto;
        }
        
        .quote-details {
          text-align: right;
        }
        
        .light-blue {
          color: #87CEFA; /* Light blue color for labels */
          font-size: 10pt;
        }
        
        .quote-number {
          font-size: 14pt;
          font-weight: bold;
          margin: 3mm 0;
        }
        
        .company-client {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15mm;
        }
        
        .company-details {
          max-width: 80mm;
          text-align: left;
        }
        
        .client-details {
          max-width: 80mm;
          text-align: right;
        }
        
        .company-name, .client-name {
          font-size: 12pt;
          font-weight: bold;
          margin-bottom: 2mm;
        }
        
        .address {
          font-size: 10pt;
          line-height: 1.5;
        }
        
        .amount-section {
          text-align: right;
          margin-bottom: 10mm;
        }
        
        .amount-value {
          font-size: 24pt;
          font-weight: bold;
          margin-bottom: 5mm;
        }
        
        .valid-until {
          margin-bottom: 5mm;
        }
        
        .overview {
          margin-bottom: 2mm;
          font-weight: bold;
          font-size: 11pt;
          text-transform: uppercase;
        }
        
        .overview-desc {
          font-size: 10pt;
          margin-bottom: 5mm;
        }
        
        table {
          width: 100%;
          border-collapse: collapse;
          margin: 5mm 0 10mm 0;
        }
        
        th {
          text-align: left;
          padding: 3mm 2mm;
          color: #646464;
          font-size: 9pt;
          font-weight: bold;
          border-bottom: 0.5pt solid #e0e0e0;
        }
        
        td {
          padding: 4mm 2mm;
          font-size: 9pt;
          border-bottom: 0.5pt solid #e0e0e0;
          vertical-align: top;
        }
        
        .summary-section {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 10mm;
        }
        
        .summary-container {
          width: 70mm;
        }
        
        .summary-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 2mm;
          font-size: 10pt;
        }
        
        .summary-row.total {
          font-weight: bold;
          margin-top: 3mm;
        }
        
        .terms-section {
          margin-top: 10mm;
        }
        
        .terms-title {
          font-weight: bold;
          margin-bottom: 2mm;
          font-size: 11pt;
        }
        
        .terms-item {
          margin-bottom: 2mm;
          font-size: 10pt;
        }
        
        .footer {
          position: absolute;
          bottom: 10mm;
          left: 0;
          right: 0;
          text-align: center;
          font-size: 8pt;
        }
      }
    </style>
  </head>
  <body>
    <div class="page">
      <!-- Header with logo and quote number -->
      <div class="header">
        <img src="https://brand-experts.b-cdn.net/designs/design_1742734835360_Picture.png" class="logo" alt="Brand Experts Logo">
        <div class="quote-details">
          <div class="light-blue">QUOTE NUMBER</div>
          <div class="quote-number">BE-${Date.now().toString().slice(-4)}</div>
          <div>SPR-${new Date().getFullYear()}-${Date.now().toString().slice(-5)}</div>
        </div>
      </div>
      
      <!-- Company and Client details -->
      <div class="company-client">
        <div class="company-details">
          <div class="company-name">Brand Experts Advertising LLC</div>
          <div class="address">
            Industrial Area 17, Sharjah Kalba Ring Road<br>
            Sharjah, United Arab Emirates<br>
            PO Box 23943<br>
            +971 6 531 4088
          </div>
        </div>
        
        <div class="client-details">
       
        </div>
      </div>
      
      <!-- Amount section -->
      <div class="amount-section">
        <div class="light-blue">AMOUNT (AED)</div>
        <div class="amount-value">AED${total}</div>
        
        <div class="valid-until">
          <div class="light-blue">VALID UNTIL</div>
          <div>${formattedValidUntil}</div>
        </div>
      </div>
      
      <!-- Overview section -->
     
      
      <!-- Items table -->
      <table>
        <thead>
          <tr>
            <th style="width: 5%;">#</th>
            <th style="width: 40%;">NAME AND DESCRIPTION</th>
            <th style="width: 10%;">UNIT</th>
            <th style="width: 8%;">QTY</th>
            <th style="width: 15%;">PRICE/RATE</th>
            <th style="width: 15%;">LINE TOTAL</th>
          </tr>
        </thead>
        <tbody>
          ${cart.cart_items
            .map((item, index) => {
              const unitPrice = (
                (Number.parseFloat(item.total) - Number.parseFloat(item.designerFee || 0)) /
                item.quantity
              ).toFixed(2)

              return `
              <tr>
                <td>${index + 1}</td>
                <td>
                  ${item.name}<br>
                  ${item.designerFee > 0 ? `Includes Designer Fee: ${item.designerFee} AED<br>` : ""}
                  ${
                    item.standardSize || `${item.customSize?.width}x${item.customSize?.height} ${item.customSize?.unit}`
                  }
                </td>
                <td>Sqm</td>
                <td>${item.quantity}</td>
                <td>AED${unitPrice}</td>
                <td>AED${item.total}<br>+VAT</td>
              </tr>
            `
            })
            .join("")}
          
          ${
            includeSiteVisit
              ? `
            <tr>
              <td>${cart.cart_items.length + 1}</td>
              <td>Site Visit Fee</td>
              <td>Service</td>
              <td>1</td>
              <td>AED${siteVisitFee}</td>
              <td>AED${siteVisitFee}<br>+VAT</td>
            </tr>
          `
              : ""
          }
        </tbody>
      </table>
      
      <!-- Summary section -->
      <div class="summary-section">
        <div class="summary-container">
          <div class="summary-row">
            <div>Subtotal</div>
            <div>AED${subtotal}</div>
          </div>
          <div class="summary-row">
            <div>VAT (5%)</div>
            <div>AED${vat}</div>
          </div>
          <div class="summary-row total">
            <div>TOTAL (AED)</div>
            <div>AED${total}</div>
          </div>
        </div>
      </div>
      
      <!-- Terms and conditions -->
      <div class="terms-section">
        <div class="terms-title">Terms and Conditions</div>
        <div class="terms-item">1. Order to be confirmed by LPO</div>
        <div class="terms-item">2. Payment: 100% on work completion</div>
        <div class="terms-item">3. Delivery: Within 3 to 5 working days after receipt of LPO artwork files</div>
      </div>
      
      <!-- Footer with page number -->
      <div class="footer">Page 1/1</div>
    </div>
    <script>
      // Auto-trigger print when content is loaded
      window.onload = function() {
        window.print();
        // Close the iframe after printing
        window.onafterprint = function() {
          window.close();
        };
      };
    </script>
  </body>
</html>
  `

    // Set the content of the iframe
    printIframe.contentWindow.document.open()
    printIframe.contentWindow.document.write(printContent)
    printIframe.contentWindow.document.close()

    // Use a small timeout to ensure content is loaded before printing
    setTimeout(() => {
      printIframe.contentWindow.print()

      // Remove the iframe after printing
      setTimeout(() => {
        document.body.removeChild(printIframe)
      }, 500)
    }, 500)
  }

  return (
    <>
      <div className="p-4 mb-4 flex justify-end gap-6">
        <button onClick={() => navigate("/saved-rfqs")} className="text-red-600 flex items-center gap-2 cursor-pointer">
          <History size={24} />
          <span className="text-sm font-medium">Load RFQ</span>
        </button>

        <button onClick={() => setShowSaveModal(true)} className="text-red-600 flex items-center gap-2 cursor-pointer">
          <Save size={24} />
          <span className="text-sm font-medium">Save RFQ</span>
        </button>

        <button onClick={() => setShowEmailModal(true)} className="text-red-600 flex items-center gap-2 cursor-pointer">
          <Mail size={24} />
          <span className="text-sm font-medium">Email RFQ</span>
        </button>

        <button onClick={() => setShowPrintModal(true)} className="text-red-600 flex items-center gap-2 cursor-pointer">
          <Printer size={24} />
          <span className="text-sm font-medium">Print RFQ</span>
        </button>

        <button
          onClick={() => setShowDownloadModal(true)}
          disabled={isGeneratingPdf}
          className="text-red-600 flex items-center gap-2 disabled:opacity-50 cursor-pointer"
        >
          {isGeneratingPdf ? <Loader size={24} className="animate-spin" /> : <FileDown size={24} />}
          <span className="text-sm font-medium">Download RFQ</span>
        </button>
      </div>

      {/* Email RFQ Modal */}
      {showEmailModal && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50 "
          style={{ backgroundColor: "rgba(0, 0, 0, 0.60)" }}
        >
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-bold mb-4">Request for Quotation</h2>
            <input
              type="email"
              placeholder="Email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isSendingRFQ}
              className="w-full border px-3 py-2 rounded-md mb-4"
            />
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={isSendingRFQ}
              className="w-full border px-3 py-2 rounded-md mb-4"
            />
            <input
              type="text"
              placeholder="Phone"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              disabled={isSendingRFQ}
              className="w-full border px-3 py-2 rounded-md mb-4"
            />
            <input
              type="text"
              placeholder="Company name"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              disabled={isSendingRFQ}
              className="w-full border px-3 py-2 rounded-md mb-4"
            />

            <div className="flex justify-end gap-3">
              <button onClick={() => setShowEmailModal(false)} className="px-4 py-2 text-gray-600 cursor-pointer">
                Cancel
              </button>
              <button
                onClick={sendRFQRequest}
                disabled={isSendingRFQ}
                className={`px-4 py-2 bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white rounded 
    ${isSavingRFQ ? "opacity-50 cursor-not-allowed" : "hover:shadow-lg transition-shadow duration-200 cursor-pointer"}`}
              >
                {isSendingRFQ ? "Sending..." : "Send RFQ"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Save RFQ Modal */}
      {showSaveModal && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50 "
          style={{ backgroundColor: "rgba(0, 0, 0, 0.60)" }}
        >
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-bold mb-4">Save RFQ</h2>
            <input
              type="text"
              placeholder="Enter name"
              value={saveName}
              onChange={(e) => setSaveName(e.target.value)}
              disabled={isSavingRFQ}
              className="w-full border px-3 py-2 rounded-md mb-4"
            />

            <div className="flex justify-end gap-3">
              <button onClick={() => setShowSaveModal(false)} className="px-4 py-2 text-gray-600 cursor-pointer">
                Cancel
              </button>
              <button
                onClick={saveRFQ}
                disabled={isSavingRFQ}
                className="px-4 py-2 bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white rounded cursor-pointer"
              >
                {isSavingRFQ ? "Saving..." : "Save RFQ"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Download RFQ Modal */}
      {showDownloadModal && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.60)" }}
        >
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-bold mb-4">Download RFQ</h2>
            <p className="text-sm text-gray-600 mb-4">Please provide your details before downloading</p>

            <input
              type="text"
              name="name"
              placeholder="Name"
              value={downloadData.name}
              onChange={handleDownloadInputChange}
              disabled={isSubmittingDownload}
              className="w-full border px-3 py-2 rounded-md mb-4"
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              autoComplete="email"
              value={downloadData.email}
              onChange={handleDownloadInputChange}
              disabled={isSubmittingDownload}
              className="w-full border px-3 py-2 rounded-md mb-4"
            />
            <input
              type="text"
              name="mobile"
              placeholder="Phone"
              value={downloadData.mobile}
              onChange={handleDownloadInputChange}
              disabled={isSubmittingDownload}
              className="w-full border px-3 py-2 rounded-md mb-4"
            />
            <input
              type="text"
              name="company"
              placeholder="Company name"
              value={downloadData.company}
              onChange={handleDownloadInputChange}
              disabled={isSubmittingDownload}
              className="w-full border px-3 py-2 rounded-md mb-4"
            />

            <div className="flex justify-end gap-3">
              <button onClick={() => setShowDownloadModal(false)} className="px-4 py-2 text-gray-600 cursor-pointer">
                Cancel
              </button>
              <button
                onClick={handleDownloadSubmit}
                disabled={isSubmittingDownload}
                className={`px-4 py-2 bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white rounded 
                ${isSubmittingDownload ? "opacity-50 cursor-not-allowed" : "hover:shadow-lg transition-shadow duration-200 cursor-pointer"}`}
              >
                {isSubmittingDownload ? "Processing..." : "Download"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Print RFQ Modal */}
      {showPrintModal && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.60)" }}
        >
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-bold mb-4">Print RFQ</h2>
            <p className="text-sm text-gray-600 mb-4">Please provide your details before printing</p>

            <input
              type="text"
              name="name"
              placeholder="Name"
              value={printData.name}
              onChange={handlePrintInputChange}
              disabled={isSubmittingPrint}
              className="w-full border px-3 py-2 rounded-md mb-4"
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              autoComplete="email"
              value={printData.email}
              onChange={handlePrintInputChange}
              disabled={isSubmittingPrint}
              className="w-full border px-3 py-2 rounded-md mb-4"
            />
            <input
              type="text"
              name="mobile"
              placeholder="Phone"
              value={printData.mobile}
              onChange={handlePrintInputChange}
              disabled={isSubmittingPrint}
              className="w-full border px-3 py-2 rounded-md mb-4"
            />
            <input
              type="text"
              name="company"
              placeholder="Company name"
              value={printData.company}
              onChange={handlePrintInputChange}
              disabled={isSubmittingPrint}
              className="w-full border px-3 py-2 rounded-md mb-4"
            />

            <div className="flex justify-end gap-3">
              <button onClick={() => setShowPrintModal(false)} className="px-4 py-2 text-gray-600 cursor-pointer">
                Cancel
              </button>
              <button
                onClick={handlePrintSubmit}
                disabled={isSubmittingPrint}
                className={`px-4 py-2 bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white rounded 
                ${isSubmittingPrint ? "opacity-50 cursor-not-allowed" : "hover:shadow-lg transition-shadow duration-200 cursor-pointer"}`}
              >
                {isSubmittingPrint ? "Processing..." : "Print"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

